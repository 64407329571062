import { defineMessages, useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { Divider, SectionDivider } from '../components/Grid/Divider/Divider';
import Hero from '../components/Hero';
import Instant from '../components/Instant';
import Layout from '../components/Layout';
import Market from '../components/Market';
import PopularQuestions from '../components/PopularQuestions';
import Sell from '../components/Sell';
import SEO, { createAlternateLinks } from '../components/Seo';
import Trustpilot from '../components/Trustpilot';
import UniqueValuePropositions from '../components/UniqueValuePropositions';
import { GAME } from '../constants';

const messages = defineMessages({
  title: {
    id: 'index.title.CSGO',
    defaultMessage:
      'Sell CS:GO Skins for Real Money & Withdraw to the e-wallet of your choice',
  },
  description: {
    id: 'index.description.CSGO',
    defaultMessage:
      'Sell your CSGO skins fast and cash out instantly! Log in with your Steam, evaluate your inventory, sell skins and pay out money in 5 minutes.',
  },
});

const getQuestions = (lang) => {
  try {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    return require(`../intl/questions/csgo/${lang}.json`);
  } catch (error) {
    return null;
  }
};

const IndexPage = ({
  location: { pathname, hash },
  pageContext: {
    intl: { originalPath },
  },
}) => {
  const { locale, formatMessage } = useIntl();
  const questions = getQuestions(locale);
  const game = GAME.CSGO;
  const alternateLinks = createAlternateLinks(originalPath);
  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage(messages.title)}
        description={formatMessage(messages.description)}
        pathname={pathname}
        link={alternateLinks}
      />
      <Layout originalPath={originalPath}>
        <Hero game={game} originalPath={originalPath} hash={hash} />
        <LazyLoadComponent placeholder={<div style={{ height: '396px' }} />}>
          <Trustpilot />
        </LazyLoadComponent>
        <Divider />
        <UniqueValuePropositions game={game} />
        <SectionDivider />
        <Instant game={game} />
        <SectionDivider />
        <Market />
        <SectionDivider />
        <Sell game={game} />
        {questions && (
          <>
            <Divider />
            <PopularQuestions questions={questions} />
          </>
        )}
      </Layout>
    </>
  );
};

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default IndexPage;
